export const FormNames = [
  "instant-callback",
  "callback",
  "contact-us",
  "advisor",
  "leaflet",
  "blueprint",
  "in-page",
  "simulator-ptz",
  "sponsorship",
  "qualification",
  "simulator-lmnp",
  "landing",
  "financial-study",
  "information",
  "event",
  "guide-invest",
  "parking",
  "plot",
] as const;

export type FormSystemName = (typeof FormNames)[number];

export function isFormSystemName(value: string): value is FormSystemName {
  return FormNames.includes(value as FormSystemName);
}

export type FormSystemNameWithSubject =
  | FormSystemName
  | "advisor-callback"
  | "advisor-appointment"
  | "advisor-question"
  | "information"
  | "preview"
  | "cnat"
  | "event";
